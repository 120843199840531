<template>
  <div class="solution2">
    <div class="banner">
      <img src="@/assets/newhome/10.png" alt="" />
    </div>
    <div class="carousel">
      <div class="cartitle">
        <p>精选案例</p>
        <span></span>
        <p>涉及行业累积100+，获得用户高度评价</p>
      </div>
      <div class="carimg">
        <!-- <el-carousel
          type="card"
          :interval="4000"
          height="7.5rem"
          indicator-position="outside"
        >
          <el-carousel-item v-for="(item, index) in carousellist" :key="index">
            <img
              src="@/assets/newhome/19.png"
              alt=""
              style="width: 100%; height: 100%"
            />
          </el-carousel-item>
        </el-carousel> -->
        <div
          class="bbox"
          v-for="(item, index) in carousellist"
          :key="index"
          v-show="num == index ? true : false"
        >
          <div class="box" v-for="(atem, andex) in item" :key="andex">
            <img :src="atem.carurl" alt="" style="width: 100%; height: 100%" />
            <p>{{ atem.src }}</p>
          </div>
        </div>
      </div>
      <div class="carhandle">
        <div class="line">
          <span
            v-for="(item, index) in carousellist"
            :key="index"
            :class="num == index ? 'active' : ''"
            @click="spanhandle(index)"
          ></span>
        </div>
      </div>
    </div>
    <div class="more">
      <div class="warp">
        <p>更多案例</p>
        <p>这里汇总了云体系相关的所有案例</p>
      </div>
    </div>
    <div class="example">
      <div class="warp">
        <ul class="exa">
          <li v-for="(item, index) in examplelist" :key="index">
            <div class="small">
              <img :src="item.conimg" alt="" />
            </div>
            <div class="smallcontent">
              <p>{{ item.title }}</p>
              <p>{{ item.con }}</p>
              <div class="labels">
                <span v-for="(atem, andex) in item.labels" :key="andex">{{
                  atem
                }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 0,
      carousellist: [
        [
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/17.png"),
          },
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/16.png"),
          },
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/17.png"),
          },
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/a19.png"),
          },
        ],
        [
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/a19.png"),
          },
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/17.png"),
          },
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/16.png"),
          },
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/17.png"),
          },
        ],
        [
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/17.png"),
          },
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/a19.png"),
          },
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/17.png"),
          },
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/16.png"),
          },
        ],
        [
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/17.png"),
          },
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/17.png"),
          },
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/16.png"),
          },
          {
            src: "2022-3-23广东达宜明MSA培训",
            carurl: require("@/assets/newhome/a19.png"),
          },
        ],
      ],
      examplelist: [
        {
          conimg: require("@/assets/newhome/15.png"),
          title: "深圳金地物业",
          con: "景翔助力深圳金地物业打造医院物业标杆项目",
          labels: ["体系维护", "体系维护", "体系维护", "体系维护"],
        },
        {
          conimg: require("@/assets/newhome/15.png"),
          title: "深圳金地物业",
          con: "景翔助力深圳金地物业打造医院物业标杆项目",
          labels: ["体系维护", "体系维护", "体系维护", "体系维护"],
        },
        {
          conimg: require("@/assets/newhome/15.png"),
          title: "深圳金地物业",
          con: "景翔助力深圳金地物业打造医院物业标杆项目",
          labels: ["体系维护", "体系维护", "体系维护", "体系维护"],
        },
        {
          conimg: require("@/assets/newhome/15.png"),
          title: "深圳金地物业",
          con: "景翔助力深圳金地物业打造医院物业标杆项目",
          labels: ["体系维护", "体系维护", "体系维护", "体系维护"],
        },
        {
          conimg: require("@/assets/newhome/15.png"),
          title: "深圳金地物业",
          con: "景翔助力深圳金地物业打造医院物业标杆项目",
          labels: ["体系维护", "体系维护", "体系维护", "体系维护"],
        },
        {
          conimg: require("@/assets/newhome/15.png"),
          title: "深圳金地物业",
          con: "景翔助力深圳金地物业打造医院物业标杆项目",
          labels: ["体系维护", "体系维护", "体系维护", "体系维护"],
        },
        {
          conimg: require("@/assets/newhome/15.png"),
          title: "深圳金地物业",
          con: "景翔助力深圳金地物业打造医院物业标杆项目",
          labels: ["体系维护", "体系维护", "体系维护", "体系维护"],
        },
        {
          conimg: require("@/assets/newhome/15.png"),
          title: "深圳金地物业",
          con: "景翔助力深圳金地物业打造医院物业标杆项目",
          labels: ["体系维护", "体系维护", "体系维护", "体系维护"],
        },
      ],
      timer: "",
    };
  },
  methods: {
    carousel() {
      this.timer = setInterval(() => {
        this.num++;
        if (this.num >= this.carousellist.length) {
          this.num = 0;
        }
      }, 5000);
    },
    spanhandle(numb) {
      clearInterval(this.timer);
      this.num = numb;
      this.carousel();
    },
  },
  created() {
    this.carousel();
  },
};
</script>


<style lang="less" scoped>
.warp {
  width: 64.8%;
  max-width: 1244px;
  margin: 0 auto;
}
.solution2 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #ffffff;
  box-sizing: border-box;
  .banner {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .carousel {
    width: 100%;
    box-sizing: border-box;
    .cartitle {
      margin-top: 0.975rem;
      text-align: center;
      p:nth-of-type(1) {
        font-size: 0.4rem;
        font-weight: 400;
        color: #434343;
        line-height: 0.4125rem;
        margin-bottom: 0;
      }
      span {
        display: inline-block;
        width: 0.5375rem;
        border-bottom: 0.05rem solid black;
        margin-top: 0.25rem;
        margin-bottom: 0.475rem;
      }
      p:nth-of-type(2) {
        margin-top: 0;
        margin-bottom: 0.425rem;
        font-size: 0.225rem;
        font-weight: 400;
        color: #666666;
      }
    }
    .carimg {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 0;
      .bbox {
        display: flex;
        width: 100%;
        .box {
          overflow: hidden;
          position: relative;
          img:hover {
            transform: scale(150%);
            transition: 1.2s;
            z-index: 990;
          }
          p {
            position: absolute;
            bottom: 0.7125rem;
            left: 19.06%;
            z-index: 999;
            font-size: 0.225rem;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.325rem;
            text-shadow: 0px 0.025rem 0px rgba(113, 113, 113, 0.11);
          }
        }
      }
    }
    .carhandle {
      margin-top: 0.75rem;
      margin-bottom: 0.675rem;
      width: 100%;
      height: 0.25rem;
      // background: pink;
      .line {
        width: 73.28%;
        margin: 0 auto;
        max-width: 1400px;
        height: 0.125rem;
        background: #d9d9d9;
        border-radius: 0.075rem;
        box-sizing: border-box;
        display: flex;
        align-content: center;
        span {
          position: relative;
          top: -0.0125rem;
          max-width: 200px;
          width: 100%;
          height: 0.1875rem;
          border-radius: 0.075rem;
          &:hover {
            cursor: pointer;
          }
        }
        .active {
          background: #24569c;
          &:hover {
            transform: scale(120%);
            transition: 0.8s;
          }
        }
      }
    }
  }
  .more {
    width: 100%;
    height: 2.3875rem;
    line-height: 2.3875rem;
    background: url("../../../assets/newhome/18.png") no-repeat;
    text-align: left;
    p:nth-of-type(1) {
      padding-top: 0.75rem;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 0.3rem;
      font-weight: 400;
      color: #000000;
      line-height: 0.4125rem;
    }
    p:nth-of-type(2) {
      margin-top: 0.2875rem;
      margin-bottom: 0;
      font-size: 0.2rem;
      font-weight: 400;
      color: #999999;
      line-height: 0.4125rem;
    }
  }
  .example {
    width: 100%;
    .exa {
      padding-left: 0;
      list-style: none;
      margin-top: 0.825rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 1.9875rem;
      li {
        margin-bottom: 0.325rem;
        box-shadow: 0px 0.025rem 0.1rem 0.0125rem rgba(84, 84, 84, 0.16);
        width: 3.625rem;
        .small {
          width: 100%;
          img {
            width: 100%;
            height: 1.9625rem;
          }
        }
        .smallcontent {
          text-align: left;
          padding-left: 0.2625rem;
          p:nth-of-type(1) {
            margin-top: 0.3125rem;
            margin-bottom: 0;
            font-size: 0.2rem;
            font-weight: bold;
            color: #000000;
            line-height: 0.3rem;
          }
          p:nth-of-type(2) {
            margin-top: 0.2875rem;
            margin-bottom: 0;
            font-size: 0.2rem;
            font-weight: 400;
            color: #787878;
            line-height: 0.3rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .labels {
            margin-top: 0.2375rem;
            margin-bottom: 0.2125rem;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            span {
              width: 1rem;
              height: 0.2625rem;
              background: #f0f1f4;
              font-size: 0.125rem;
              font-weight: 400;
              color: #787878;
              text-align: center;
              transform: scale(70%);
              margin-bottom: 0.125rem;
            }
          }
        }
      }
    }
  }
}
</style>